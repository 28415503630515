// this contains all the permissions required in landing/login page
export const loginPermissions = {
  'acp:rising_tide_eligible': true,
  'acp:login_mfe_eligible': true,
  'webapi:apptentive-feedback-enabled': true,
  'acp:deep_linking_enabled': true,
  'webapi:biometrics_login_enabled': true,
  'webapi:face_auth_enabled': true,
  'webapi:esign': true,
  'webapi:AlertsTermsV2Accepted': true,
  'acp:push_notifications': true,
  'pubweb:directdeposit': true,
  'webapi:netspend_payback_loyalty_eligible': true,
  'webapi:payback_rewards_eligible': true,
  'acp:pn_promotion_subscription_kill_switch': true,
  'webapi:show_additional_activate_button_loginscreen': true,
  'acp:suspicious_transaction_review': true,
  'acp:hold_txn_type_pn_enabled': true,
  'webapi:show_login_terms_and_conditions_disclosure': true,
  'webapi:show_scroll_to_navigation_links': true,
  'webapi:show_open_new_account_button_bg': true,
  'acp:join_today_mobile_eligible': true,
  'acp:show_mastercard_disclosures': true,
  'acp:show_walmart_disclosure': true,
  'acp:show_walmart_disclosure-Reporting': true,
  'acp:show_card_use_disclosure': true,
  'acp:show_short_apple_disclosure': true,
  'acp:login_preferences_mfe_eligible': true,
  'acp:show_appsflyer_banner': true,
  'webapi:showChangedTermsDepositPrivacyDisclosure': true,
  'acp:fail_me': true,
  'oac:dashboard_v2_redesign': true
};
