//
// Create all Injectable Services
//
import {
  createInjectable,
  baseUrl as gatewayRoot
} from 'packages/gateway/gateway';
import { createReactErrorHandling } from './error-handling';
import { createReactSuspenseHandling } from './loading-handler';
import { createBackButtonHandlerPlugin } from 'packages/back-button-handler/plugin';
import { createWebExternalLinkOpenerPlugin } from 'packages/external-link-opener/plugin';
import { LegoTheme } from 'legos/theme';
import { commonNetspendApiFormResponseTransformer } from 'packages/http-client/api-transformer';
import {
  createCordovaIovationPlugin,
  createWebFirstPartyIovationPlugin,
  createWebIovationPlugin
} from 'packages/iovation/plugin';
import { createAcquireNewCustomer } from 'apps/acp/packages/acquire-new-customer';
import {
  createFileDownloaderPluginMobile,
  createFileDownloaderPluginBrowser
} from 'apps/acp/packages/file-downloader-plugin';
import { createStatusBarPlugin } from 'apps/acp/packages/status-bar-plugin';
import {
  ConfigurationItem,
  createPostConfigurationRequest,
  GetConfigurationResponse
} from 'apps/acp/packages/webapi';
import { createLayout } from 'apps/acp/gateway/packages/rising-tide-layout';
import { createUnauthedLayout } from 'apps/acp/gateway/packages/unauthed-layout';
import { createUnauthedLayoutv2 } from 'apps/acp/gateway/packages/unauthed-layout-v2';
import {
  dashboard,
  login,
  logout,
  manageAccount,
  moveMoney,
  spendingAccount,
  notifications,
  offers,
  cashRewards,
  externalLoyalty,
  paypalTransfers,
  speedyRewards,
  wuTransfers,
  albertsonsDollars,
  cashback,
  activateCard,
  register,
  contact,
  locationFinder,
  loyalty,
  atmFinder
} from './declarations';
import { createAcpEnvironment } from './environment';
import { webapiFetcherBehaviorSubjectFactory } from './webapi-fetcher';
import { logoLocatorBehaviorSubjectFactory } from 'apps/acp/packages/logo-locator';
import {
  sessionManagerBehaviorSubjectFactory,
  sessionExpirationBehaviorSubjectDispatcherFactory
} from './session-manager';
import {
  webapiPermissionResolverSubscribeFactory,
  createDeclarativePermissionApi
} from './permissions';
import {
  webapiAuthenticationResolverSubscribeFactory,
  createDeclerativeAuthenticationApi
} from './authentication';
import {
  createGTMAnalyticsConfig,
  createGTMAdobeLaunchSendAnalytics,
  createAdobeLaunchConfig
} from './analytics';
import { getCordovaIfApplicable } from './util/cordova';
import { getStatusBarIfApplicable } from './util/status-bar';
// TODO (8/17/2020 kloy2) icons should be dynamic imports
import { bottomBarIcons } from 'apps/acp/gateway/bottom-bar-icons';
import { createAdobeLaunchSendAnalytics } from 'apps/acp/gateway/packages/adobe-launch';
import { createGoogleTagManagerSendAnalytics } from 'apps/acp/gateway/packages/gtm';
import {
  BiometricsStorageCordovaPlugin,
  BiometricsStorageNoopPlugin
} from 'apps/acp/packages/acp-biometrics';
import {
  CordovaPushNotificationNoopPlugin,
  CordovaPushNotificationPlugin
} from 'packages/push-plugin/api';
import {
  AppsflyerCordovaPlugin,
  AppsflyerNoopPlugin,
  AppsflyerWebPlugin
} from 'packages/appsflyer-plugin/api';
// eslint-disable-next-line @netspend/react-content
import { updateContentFilters } from 'packages/react-content/runtime';
import { bsGetValue, bsSwitchMap } from 'packages/behavior-subject/fn';
import { mapVariantToAcpEnv } from 'apps/acp/gateway/map-variant';
import { AcpEnvironment } from '../packages/acp-config';
import { brandingFetcherBehaviorSubjectFactory } from 'apps/acp/packages/branding-fetcher';
import { createBehaviorSubject } from 'packages/behavior-subject';
import { isPreOnboardingRedirectionNeeded } from 'apps/acp/packages/pre-onboarding-eligibility';
import {
  DynatraceNoopPlugin,
  DynatracePlugin
} from 'apps/acp/packages/acp-dynatrace';
import {
  CordovaPaypalMagnesPlugin,
  CordovaPaypalMagnesNoopPlugin
} from 'packages/paypal-magnes-plugin/api';
import { createWebAtomicTransactPlugin } from 'packages/atomic-transact/plugin';
import {
  ApplePayCordovaNoopPlugin,
  ApplePayCordovaPlugin
} from 'packages/apple-pay-plugin/api';
import {
  GooglePayCordovaNoopPlugin,
  GooglePayCordovaPlugin
} from 'packages/google-pay-plugin/api';
import {
  SamsungPayCordovaNoopPlugin,
  SamsungPayCordovaPlugin
} from 'packages/samsung-pay-plugin/api';
import {
  createCordovaClipboardPlugin,
  createWebClipboardPlugin
} from 'packages/clipboard-plugin/plugin';
import {
  createCordovaPrinterPlugin,
  createWebPrinterPlugin
} from 'packages/printer-plugin/plugin';
import {
  ApptentiveCordovaNoopPlugin,
  ApptentiveCordovaPlugin
} from 'packages/apptentive-plugin/plugin';
import { derivePlatform } from './util/platform';
import { AdaChatbotPlugin } from 'packages/ada-chatbot/plugin';
import { BrazeNoopPlugin, CordovaBrazePlugin } from 'packages/braze-plugin/api';

export const cordova = createInjectable(() => () => getCordovaIfApplicable());

export const initialACPVariant = createInjectable(
  () => ({ cordova }) => createAcpEnvironment(cordova),
  { cordova }
);

export const getToken = (): string | undefined => {
  const token = localStorage.getItem('acp_device_token');

  if (!token) return undefined;

  const jsonString = window.atob(token);
  return JSON.parse(jsonString);
};

export const variantIdBehaviorSubject = createInjectable(
  () => ({ initialACPVariant }) => {
    const BSVariantID = createBehaviorSubject(initialACPVariant.config.variant);
    return BSVariantID;
  },
  { initialACPVariant }
);

export const variantId = createInjectable(
  () => ({ variantIdBehaviorSubject }) => variantIdBehaviorSubject[1],
  { variantIdBehaviorSubject }
);

export const variantIdDispatcher = createInjectable(
  () => ({ variantIdBehaviorSubject }) => variantIdBehaviorSubject[0],
  { variantIdBehaviorSubject }
);

export const ErrorHandling = createInjectable(
  () => (deps) =>
    createReactErrorHandling({
      logoutLink: deps.logout,
      returnLink: deps.dashboard,
      appVersion: deps.initialACPVariant.config.appVersion
    }),
  { logout, dashboard, initialACPVariant }
);

export const bsAcpEnv = createInjectable(
  () => ({ variantId, ErrorHandling, cordova }) =>
    bsSwitchMap(
      variantId,
      async (variantId) => {
        const variant = await import(
          /* rollupIgnoreUnsafeDynamicImport */ `apps/acp/variants/${variantId}`
        );

        let mappedAcpEnv = mapVariantToAcpEnv(variant.default);
        mappedAcpEnv = await createAcpEnvironment(cordova, mappedAcpEnv);

        return mappedAcpEnv;
      },
      () => ErrorHandling
    ),
  {
    variantId,
    ErrorHandling,
    cordova
  }
);

export const biometricsPlugin = createInjectable(
  () => () => {
    if (window.plugins && window.plugins.touchid) {
      return new BiometricsStorageCordovaPlugin(window.plugins.touchid);
    }
    return new BiometricsStorageNoopPlugin();
  },
  { cordova }
  //Cordova is injected here to make sure it has fully loaded before checking if windows.plugins.touchId exists
);

export const dynatracePlugin = createInjectable(() => () => {
  if (window && window.dtrum) {
    return new DynatracePlugin(window.dtrum);
  }
  return new DynatraceNoopPlugin();
});

export const paypalMagnesPlugin = createInjectable(
  () => () => {
    if (window.plugins && window.plugins.paypalMagnes) {
      return new CordovaPaypalMagnesPlugin(window.plugins.paypalMagnes);
    }
    return new CordovaPaypalMagnesNoopPlugin();
  },
  { cordova }
  //Cordova is injected here to make sure it has fully loaded before checking if windows.plugins.paypalMagnes exists
);

export const applePayPlugin = createInjectable(
  () => () => {
    if (window.plugins && window.plugins.applepay) {
      return new ApplePayCordovaPlugin(window.plugins.applepay);
    }
    return new ApplePayCordovaNoopPlugin();
  },
  { cordova }
  //Cordova is injected here to make sure it has fully loaded before checking if windows.plugins.applepay exists
);

export const googlePayPlugin = createInjectable(
  () => () => {
    if (window.plugins && window.plugins.googlepay) {
      return new GooglePayCordovaPlugin(window.plugins.googlepay);
    }
    return new GooglePayCordovaNoopPlugin();
  },
  { cordova }
  //Cordova is injected here to make sure it has fully loaded before checking if windows.plugins.googlepay exists
);

export const samsungPayPlugin = createInjectable(
  () => () => {
    if (window.plugins && window.plugins.samsungpay) {
      return new SamsungPayCordovaPlugin(window.plugins.samsungpay);
    }
    return new SamsungPayCordovaNoopPlugin();
  },
  { cordova }
  //Cordova is injected here to make sure it has fully loaded before checking if windows.plugins.samsungpay exists
);

/* tslint:disable-next-line:no-empty-function */
const noop = () => {
  /* noop */
};

export const mount = createInjectable(() => () =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  document.getElementById('app')!
);

export const legoTheme = createInjectable(
  () => ({ bsAcpEnv, ErrorHandling }) =>
    bsSwitchMap(
      bsAcpEnv,
      async (acpEnv): Promise<LegoTheme> => acpEnv.colors,
      () => ErrorHandling
    ),
  {
    bsAcpEnv,
    ErrorHandling
  }
);

// TODO: add back button plugin
export const backButtonHandlerPlugin = createInjectable(() => () =>
  createBackButtonHandlerPlugin(noop as any)
);

export const bulkLegoTheme = createInjectable(
  () => ({ bsAcpEnv, ErrorHandling }) =>
    bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => acpEnv.config.themeConfiguration,
      () => ErrorHandling
    ),
  {
    bsAcpEnv,
    ErrorHandling
  }
);

export const SuspenseHandling = createInjectable(() => () =>
  createReactSuspenseHandling()
);

export const externalLinkOpenerPlugin = createInjectable(() => () =>
  createWebExternalLinkOpenerPlugin()
);

export const atomicTransactPlugin = createInjectable(() => () =>
  createWebAtomicTransactPlugin()
);

export const clipboardPlugin = createInjectable(
  () => ({ cordova }) =>
    cordova
      ? createCordovaClipboardPlugin(cordova.plugins.clipboard)
      : createWebClipboardPlugin(window),
  { cordova }
);

export const createInjectableStaticValue = (value: any) =>
  createInjectable(() => () => value);

export const formResponseTransformer = createInjectable(() => () =>
  commonNetspendApiFormResponseTransformer
);

// TODO remove baseUrl as a ReactMFE/Router dep
export const baseUrl = createInjectableStaticValue('/');

export const gatewayBaseUrl = createInjectableStaticValue(gatewayRoot);

export const webapiAccessTokenBehaviorSubject = createInjectable(
  () => sessionManagerBehaviorSubjectFactory
);

export const webapiAccessToken = createInjectable(
  () => (deps) => deps.webapiAccessTokenBehaviorSubject[1],
  { webapiAccessTokenBehaviorSubject }
);

export const webapiAccessTokenDispatcher = createInjectable(
  () => (deps) => deps.webapiAccessTokenBehaviorSubject[0],
  { webapiAccessTokenBehaviorSubject }
);

export const sessionExpirationDispatcher = createInjectable(
  () => (deps) =>
    sessionExpirationBehaviorSubjectDispatcherFactory(deps.logout),
  { logout: logout }
);

export const webapiFetcherBehaviorSubject = createInjectable(
  () => webapiFetcherBehaviorSubjectFactory,
  {
    acpEnvironment: bsAcpEnv,
    accessTokenSubscribe: webapiAccessToken,
    sessionExpirationDispatcher: sessionExpirationDispatcher
  }
);

export const webapiFetcher = createInjectable(
  () => (deps) => deps.webapiFetcherBehaviorSubject[1],
  { webapiFetcherBehaviorSubject }
);

export const webapiPermissionResolverSubscribe = createInjectable(
  () => webapiPermissionResolverSubscribeFactory,
  { fetcherSubscribe: webapiFetcher }
);

export const declarativePermissionApi = createInjectable(
  () => ({ permissionsResolverSubscribe }) =>
    createDeclarativePermissionApi(permissionsResolverSubscribe),
  {
    permissionsResolverSubscribe: webapiPermissionResolverSubscribe
  }
);

export const webapiAuthenticationResolverSubscribe = createInjectable(
  () => webapiAuthenticationResolverSubscribeFactory,
  { fetcherSubscribe: webapiFetcher }
);

export const declarativeAuthenticationApi = createInjectable(
  () => ({ authenticationResolverSubscribe }) =>
    createDeclerativeAuthenticationApi(authenticationResolverSubscribe),
  {
    authenticationResolverSubscribe: webapiAuthenticationResolverSubscribe
  }
);

export const initialFeConfiguration = createInjectable(
  () => ({ fetcherSubscribe }) => {
    // Load FE-Config once
    return new Promise<GetConfigurationResponse>((resolve) => {
      let feConfigUnsubscribe = (): void => void 0;
      const fetcherUnsubscribe = fetcherSubscribe((fetcher) => {
        const [makeRequest, onRequestUpdate] = fetcher(
          createPostConfigurationRequest([
            ConfigurationItem.adobeLaunchUrl,
            ConfigurationItem.iovationUrl,
            ConfigurationItem.iovationV2Enabled,
            ConfigurationItem.acquisitionUrl
          ])
        );
        // Dispose of old subscriber
        feConfigUnsubscribe();
        // Kick off the request if its not already going
        makeRequest();
        // Make a subscription to the new request
        feConfigUnsubscribe = onRequestUpdate(([, response, data, error]) => {
          if (error || response?.ok === false) {
            // Retry the cache and bust the cache.
            // TODO: infinite retry here... but we do not have a good pattern
            // for handling issues here. Exponential back-off should occur in the
            // fetcher itself as an extra decorator probably
            makeRequest(true);
          }
          if (response?.ok) {
            feConfigUnsubscribe();
            fetcherUnsubscribe();
            resolve(data);
          }
        });
      });
    });
  },
  { fetcherSubscribe: webapiFetcher }
);

export const iovationPlugin = createInjectable(
  () => ({ cordova, initialFeConfiguration }) => {
    if (cordova && window.plugins?.iovation) {
      return createCordovaIovationPlugin(window.plugins.iovation);
    }

    if (initialFeConfiguration['iovation.v2']) {
      return createWebFirstPartyIovationPlugin(window);
    }

    if (!initialFeConfiguration['iovation.url']) {
      throw new Error('FE-Config missing iovation.url');
    }
    return createWebIovationPlugin(initialFeConfiguration['iovation.url']);
  },
  { cordova, initialFeConfiguration }
);

export const gtmSendAnalytics = createInjectable(
  () => ({ bsAcpEnv }) =>
    bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) =>
        createGoogleTagManagerSendAnalytics(createGTMAnalyticsConfig(acpEnv)),
      () => ErrorHandling
    ),
  { bsAcpEnv }
);

export const appsFlyerPlugin = createInjectable(
  () => async ({ bsAcpEnv, gtmSendAnalytics, permission }) => {
    const acpEnv = bsGetValue(bsAcpEnv);
    const sendAnalytics = bsGetValue(gtmSendAnalytics);
    const isAppsflyerEnabled = await permission({
      'acp:deep_linking_enabled': true
    });
    const { appsflyerDevKey, iOSAppId } = acpEnv.config;
    if (!isAppsflyerEnabled) {
      return new AppsflyerNoopPlugin();
    } else if (
      isAppsflyerEnabled &&
      appsflyerDevKey &&
      iOSAppId &&
      window.plugins &&
      window.plugins.appsFlyer &&
      window.IonicDeeplink
    ) {
      return new AppsflyerCordovaPlugin(
        window.plugins.appsFlyer,
        window.IonicDeeplink,
        appsflyerDevKey,
        iOSAppId
      );
    } else {
      return new AppsflyerWebPlugin(sendAnalytics);
    }
  },
  { bsAcpEnv, gtmSendAnalytics, cordova, permission: declarativePermissionApi }
);

export const gtmAdobeLaunchSendAnalytics = createInjectable(
  () => ({ bsAcpEnv, initialFeConfiguration }) =>
    bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) =>
        createGTMAdobeLaunchSendAnalytics(
          createAdobeLaunchSendAnalytics(
            createAdobeLaunchConfig(
              acpEnv,
              initialFeConfiguration['adobe.launch.url']
            )
          ),
          createGoogleTagManagerSendAnalytics(createGTMAnalyticsConfig(acpEnv))
        ),
      () => ErrorHandling
    ),
  { bsAcpEnv, initialFeConfiguration }
);

export const brandingFetcherBehaviorSubject = createInjectable(
  () => brandingFetcherBehaviorSubjectFactory,
  { webapiFetcher }
);

export const brandingFetcher = createInjectable(
  () => ({ brandingFetcherBehaviorSubject }) =>
    brandingFetcherBehaviorSubject[1],
  { brandingFetcherBehaviorSubject }
);

export const logoLocatorBehaviorSubject = createInjectable(
  () => logoLocatorBehaviorSubjectFactory,
  {
    acpEnvironment: bsAcpEnv,
    brandingFetcher,
    ErrorHandling,
    permission: declarativePermissionApi
  }
);

export const logoLocator = createInjectable(
  () => ({ logoLocatorBehaviorSubject }) => logoLocatorBehaviorSubject[1],
  { logoLocatorBehaviorSubject }
);

export const logoLocatorDispatcher = createInjectable(
  () => ({ logoLocatorBehaviorSubject }) => logoLocatorBehaviorSubject[0],
  { logoLocatorBehaviorSubject }
);

export const fileDownloaderPlugin = createInjectable(
  () => ({ cordova }) => {
    return cordova
      ? createFileDownloaderPluginMobile(cordova)
      : createFileDownloaderPluginBrowser();
  },
  { cordova }
);

export const statusBar = createInjectable(
  () => ({ cordova }) => getStatusBarIfApplicable(cordova),
  { cordova }
);

export const statusBarPlugin = createInjectable(
  () => ({ statusBar }) => {
    return createStatusBarPlugin(statusBar);
  },
  { statusBar }
);

export const contentFiltersListener = createInjectable(
  () => ({
    brandingFetcher,
    bsAcpEnv,
    variantId,
    variantIdDispatcher,
    webapiAccessToken
  }) =>
    bsSwitchMap(
      bsAcpEnv,
      async (acpEnvironment: AcpEnvironment) => {
        return brandingFetcher((brandingData) => {
          const currentVariantId = bsGetValue(variantId);
          const brandingVariantID =
            !!brandingData &&
            brandingData.brand?.variant_id.split(`variant://`);
          if (
            brandingVariantID &&
            brandingVariantID.length > 1 &&
            currentVariantId !== brandingVariantID[1]
          ) {
            // Check web only and load the base variant
            // comment below section to mock variants in web
            const accessToken = bsGetValue(webapiAccessToken);
            const isWeb = derivePlatform() === 'web';
            if (
              currentVariantId.includes('/') &&
              isWeb &&
              !accessToken?.access_token.length
            ) {
              window.location.replace(
                window.location.origin +
                  window.location.pathname +
                  '?tenant=' +
                  currentVariantId.split('/')[0]
              );
            }
            // if user doesnt belong to child variant force reload to base variant
            if (
              currentVariantId.includes('/') &&
              !brandingVariantID[1].includes('/')
            ) {
              window.location.replace(
                window.location.origin +
                  window.location.pathname +
                  '?tenant=' +
                  brandingVariantID[1]
              );
            } else variantIdDispatcher(brandingVariantID[1]);
          }
          updateContentFilters(
            brandingData
              ? //If we have an OK response we can set the proper filter values
                {
                  programType: (
                    brandingData?.type || acpEnvironment.config.programType
                  )?.toLowerCase(),
                  association: brandingData.association?.toLowerCase() || '',
                  bank: brandingData.bank?.toLowerCase() || ''
                }
              : // If we do not have a ok response, we need to set
                // this to null to indicate we are in a loading state
                null
          );
        });
      },
      () => ErrorHandling
    ),
  {
    brandingFetcher,
    bsAcpEnv,
    variantId,
    variantIdDispatcher,
    webapiAccessToken
  }
);

export const Layout = createInjectable(
  () => ({ bsAcpEnv, statusBarPlugin, logoLocator, permissions }) =>
    bsSwitchMap(
      bsAcpEnv,
      async (acpEnv: AcpEnvironment) => {
        const isNewVersionLayout = await permissions({
          'oac:dashboard_v2_redesign': true
        });

        const homeIcon = isNewVersionLayout
          ? bottomBarIcons.homeIconV2
          : bottomBarIcons.homeIcon;
        const moveMoneyIcon = isNewVersionLayout
          ? bottomBarIcons.moveMoneyIconV2
          : bottomBarIcons.moveMoneyIcon;
        const accountIcon = isNewVersionLayout
          ? bottomBarIcons.accountIconV2
          : bottomBarIcons.accountIcon;
        const payPalTransfersIcon = isNewVersionLayout
          ? bottomBarIcons.paypalTransfersIconV2
          : bottomBarIcons.paypalTransfersIcon;

        return createLayout({
          appVersion: acpEnv.config.appVersion,
          logoLocator,
          fallbackNavbarItems: ['home', 'move-money', 'rewards', 'account'],
          homeLink: dashboard.root,
          variantId: acpEnv.config.variant,
          navbarItems: [
            {
              apiId: 'home',
              Icon: homeIcon,
              link: dashboard.root,
              text: 'Home',
              linksWillMakeItemActive: [spendingAccount.root]
            },
            {
              apiId: 'move-money',
              Icon: moveMoneyIcon,
              link: moveMoney.root,
              text: 'Move Money'
            },
            {
              apiId: 'rewards',
              Icon:
                acpEnv.config.cashRewardsConfig.icon ||
                bottomBarIcons.rewardsIcon,
              link: acpEnv.config.isCashRewardProgram
                ? cashRewards.root
                : offers.root,
              text: acpEnv.config.cashRewardsConfig.name || 'Rewards'
            },
            {
              apiId: 'account',
              Icon: accountIcon,
              link: manageAccount.root,
              text: 'Account'
            },
            {
              apiId: 'paypal-transfers',
              Icon: payPalTransfersIcon,
              link: paypalTransfers.root,
              text: 'Transfers'
            },
            {
              apiId: 'wu-transfers',
              Icon: bottomBarIcons.rewardsIcon,
              link: wuTransfers.root,
              text: 'Transfers'
            },
            {
              apiId: 'speedy-rewards',
              Icon: bottomBarIcons.rewardsTrophyIcon,
              link: speedyRewards.root,
              text: 'Rewards'
            },
            {
              apiId: 'external-loyalty',
              Icon:
                acpEnv.config.externalLoyaltyConfig.icon ||
                bottomBarIcons.rewardsIcon,
              link: externalLoyalty.root,
              text: acpEnv.config.externalLoyaltyConfig.name || 'Fuelperks'
            },
            {
              apiId: 'points',
              Icon: bottomBarIcons.rewardsTrophyIcon,
              link: albertsonsDollars.root,
              text: 'Dollars'
            },
            {
              apiId: 'cashback',
              Icon: bottomBarIcons.cashbackIcon,
              link: cashback.root,
              text: 'Cash Back'
            },
            {
              apiId: 'loyalty-points',
              Icon: bottomBarIcons.rewardsIcon,
              link: loyalty.root,
              text: 'Points'
            }
          ],
          appBarInverted: acpEnv.config.layoutAppBarInverted,
          notificationsLink: notifications.root,
          statusBarPlugin,
          statusBarBackgroundColor: acpEnv.config.statusBarBackgroundColor,
          statusBarForegroundColor: acpEnv.config.statusBarForegroundColor
        });
      },
      () => ErrorHandling
    ),
  {
    bsAcpEnv,
    statusBarPlugin,
    logoLocator,
    permissions: declarativePermissionApi
  }
);

export const AcquireNewCustomer = createInjectable(
  () => ({ bsAcpEnv, initialFeConfiguration, ErrorHandling }) =>
    bsSwitchMap(
      bsAcpEnv,
      async (acpEnv) => {
        const {
          inAppBrowserAcquisitionToolbarBackgroundColor: inAppBrowserToolbarBackgroundColor,
          inAppBrowserAcquisitionDisableToolbarText: inAppBrowserDisableToolbarText,
          inAppBrowserAcquisitionDisableToolbarTranslucent: inAppBrowserDisableToolbarTranslucent
        } = acpEnv.config;

        return createAcquireNewCustomer({
          inAppBrowserToolbarBackgroundColor,
          inAppBrowserDisableToolbarText,
          inAppBrowserDisableToolbarTranslucent,
          acquisitionUrl:
            initialFeConfiguration[ConfigurationItem.acquisitionUrl],
          loginMfeRoot: login.root
        });
      },
      () => ErrorHandling
    ),
  {
    bsAcpEnv,
    initialFeConfiguration,
    ErrorHandling
  }
);

export const UnauthedLayout = createInjectable(
  () => ({
    bsAcpEnv,
    statusBarPlugin,
    logoLocator,
    contactLinks,
    activateCardLinks,
    registerLinks,
    locationFinderLinks,
    logoutLinks,
    loginLinks,
    atmFinderLinks,
    webapiPermissionResolverSubscribe,
    AcquireNewCustomer
  }) =>
    bsSwitchMap(
      bsAcpEnv,
      async (acpEnv: AcpEnvironment) => {
        return createUnauthedLayout({
          version: acpEnv.config.version,
          mode: acpEnv.config.mode,
          logoLocator,
          variantId: acpEnv.config.variant,
          appBarInverted: acpEnv.config.layoutAppBarInverted,
          statusBarPlugin,
          statusBarBackgroundColor: acpEnv.config.statusBarBackgroundColor,
          statusBarForegroundColor: acpEnv.config.statusBarForegroundColor,
          copyrightStatement: acpEnv.config.copyrightStatement,
          loyaltyProgramDetailDisclaimer:
            acpEnv.config.loyaltyProgramDetailDisclaimer,
          fasterFundingDisclaimer: acpEnv.config.fasterFundingDisclaimer,
          paybackRewardsDisclaimer: acpEnv.config.paybackRewardsDisclaimer,
          cardDisclaimer: acpEnv.config.cardDisclaimer,
          importantPatriotActDisclosure:
            acpEnv.config.importantPatriotActDisclosure,
          platformType: acpEnv.config.platformType,
          programType: acpEnv.config.programType,
          showRegisterLink: acpEnv.config.showRegisterLink,
          showMarketingSiteLink: acpEnv.config.showMarketingSiteLink,
          marketingSiteUrl: acpEnv.config.marketingSiteUrl,
          marketingSiteLinkText: acpEnv.config.marketingSiteLinkText,
          contactLinks,
          activateCardLinks,
          registerLinks,
          locationFinderLinks,
          logoutLinks,
          loginLinks,
          atmFinderLinks,
          showReloadLocationLink: acpEnv.config.showReloadLocationLink,
          showAcquisitionLink: acpEnv.config.showAcquisitionLink,
          showFreeAtmFinderLink: acpEnv.config.showFreeAtmFinderLink,
          openAccountFooterContent: acpEnv.config.openAccountFooterContent,
          permissionsResolverSubscribe: webapiPermissionResolverSubscribe,
          AcquireNewCustomer
        });
      },
      () => ErrorHandling
    ),
  {
    bsAcpEnv,
    statusBarPlugin,
    logoLocator,
    contactLinks: contact,
    activateCardLinks: activateCard,
    registerLinks: register,
    locationFinderLinks: locationFinder,
    logoutLinks: logout,
    loginLinks: login,
    atmFinderLinks: atmFinder,
    webapiPermissionResolverSubscribe,
    AcquireNewCustomer
  }
);

export const UnauthedLayoutV2 = createInjectable(
  () => ({
    bsAcpEnv,
    statusBarPlugin,
    logoLocator,
    contactLinks,
    activateCardLinks,
    registerLinks,
    locationFinderLinks,
    logoutLinks,
    loginLinks,
    atmFinderLinks,
    webapiPermissionResolverSubscribe,
    AcquireNewCustomer
  }) =>
    bsSwitchMap(
      bsAcpEnv,
      async (acpEnv: AcpEnvironment) => {
        return createUnauthedLayoutv2({
          version: acpEnv.config.version,
          mode: acpEnv.config.mode,
          logoLocator,
          variantId: acpEnv.config.variant,
          appBarInverted: acpEnv.config.layoutAppBarInverted,
          statusBarPlugin,
          statusBarBackgroundColor: acpEnv.config.statusBarBackgroundColor,
          statusBarForegroundColor: acpEnv.config.statusBarForegroundColor,
          copyrightStatement: acpEnv.config.copyrightStatement,
          loyaltyProgramDetailDisclaimer:
            acpEnv.config.loyaltyProgramDetailDisclaimer,
          fasterFundingDisclaimer: acpEnv.config.fasterFundingDisclaimer,
          paybackRewardsDisclaimer: acpEnv.config.paybackRewardsDisclaimer,
          cardDisclaimer: acpEnv.config.cardDisclaimer,
          importantPatriotActDisclosure:
            acpEnv.config.importantPatriotActDisclosure,
          platformType: acpEnv.config.platformType,
          programType: acpEnv.config.programType,
          showRegisterLink: acpEnv.config.showRegisterLink,
          showMarketingSiteLink: acpEnv.config.showMarketingSiteLink,
          marketingSiteUrl: acpEnv.config.marketingSiteUrl,
          marketingSiteLinkText: acpEnv.config.marketingSiteLinkText,
          contactLinks,
          activateCardLinks,
          registerLinks,
          // gaCategory,
          locationFinderLinks,
          logoutLinks,
          loginLinks,
          atmFinderLinks,
          showReloadLocationLink: acpEnv.config.showReloadLocationLink,
          showAcquisitionLink: acpEnv.config.showAcquisitionLink,
          showFreeAtmFinderLink: acpEnv.config.showFreeAtmFinderLink,
          openAccountFooterContent: acpEnv.config.openAccountFooterContent,
          permissionsResolverSubscribe: webapiPermissionResolverSubscribe,
          AcquireNewCustomer
        });
      },
      () => ErrorHandling
    ),
  {
    bsAcpEnv,
    statusBarPlugin,
    logoLocator,
    contactLinks: contact,
    activateCardLinks: activateCard,
    registerLinks: register,
    locationFinderLinks: locationFinder,
    logoutLinks: logout,
    loginLinks: login,
    atmFinderLinks: atmFinder,
    webapiPermissionResolverSubscribe,
    AcquireNewCustomer
  }
);

export const isPreOnboardingEligible = createInjectable(
  () => async ({ bsAcpEnv, cordova, permissions }) => {
    const acpEnv = bsGetValue(bsAcpEnv);
    const isPreOnboardingEnabled = acpEnv.config.preOnboardingEnabled;
    return await isPreOnboardingRedirectionNeeded(
      isPreOnboardingEnabled,
      permissions,
      cordova
    );
  },
  { bsAcpEnv, cordova, permissions: declarativePermissionApi }
);

export const printerPlugin = createInjectable(
  () => ({ cordova }) =>
    cordova
      ? createCordovaPrinterPlugin(cordova.plugins.printer)
      : createWebPrinterPlugin(window),
  { cordova }
);

export const pushPlugin = createInjectable(
  () => ({ cordova, legoTheme }) => {
    const legoColors = bsGetValue(legoTheme);
    if (cordova && window?.PushNotification) {
      return new CordovaPushNotificationPlugin(
        window.PushNotification,
        getToken,
        legoColors.default.color500
      );
    }
    return new CordovaPushNotificationNoopPlugin();
  },
  { cordova, legoTheme }
  //Cordova is injected here to make sure it has fully loaded before checking if window.PushNotification exists
);

export const brazePlugin = createInjectable(
  () => ({ cordova }) => {
    if (cordova && window?.BrazePlugin) {
      return new CordovaBrazePlugin(window.BrazePlugin);
    } else {
      return new BrazeNoopPlugin();
    }
  },
  { cordova }
  //Cordova is injected here to make sure it has fully loaded before checking if window.BrazePlugin exists
);

export const apptentivePlugin = createInjectable(
  () => async ({ cordova, permission }) => {
    const isApptentiveEnabled = await permission({
      'webapi:apptentive-feedback-enabled': true
    });
    if (cordova && window?.Apptentive && isApptentiveEnabled) {
      return new ApptentiveCordovaPlugin(
        window.Apptentive,
        isApptentiveEnabled
      );
    }
    return new ApptentiveCordovaNoopPlugin();
  },
  { cordova, permission: declarativePermissionApi }
  //Cordova is injected here to make sure it has fully loaded before checking if windows.Apptentive exists
);

export const adaChatbot = createInjectable(
  () => async ({ bsAcpEnv, permission }) => {
    const chatPermission = await permission({
      'webapi:ada_chatbot_feature_enabled': true
    });
    const acpEnv = bsGetValue(bsAcpEnv);
    new AdaChatbotPlugin(chatPermission, acpEnv.config.adaChatbotHandle);
  },
  {
    bsAcpEnv,
    permission: declarativePermissionApi
  }
);
